<template>

	<section class="register">
		<div class="container-scroller">
			<div class="container-fluid page-body-wrapper full-page-wrapper">
				<div class="content-wrapper d-flex align-items-center auth">
					<div class="row w-100 flex-grow">
						<div class="col-xl-4 col-lg-6 mx-auto">
							<div class="auth-form-light text-left p-5">
								<!-- <div class="brand-logo">
									<img src="../../../assets/images/logo.svg">
								</div> -->
								<!-- <h4>New here?</h4> -->
								<h6 class="font-weight-bold text-center">KONFIRMASI EMAIL</h6>
								<form class="pt-3">
									<div class="form-group">
										<input type="email" class="form-control form-control-lg" id="exampleInputEmail1" placeholder="Email">
									</div>
									<div class="mt-3">
										<!-- <router-link class="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn" to="/">SIGN UP</router-link> -->
                    <b-button class="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn" @click="onSubmit">KONFIRMASI</b-button>										
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
				<!-- content-wrapper ends -->
			</div>
			<!-- page-body-wrapper ends -->
		</div>
	</section>

</template>

<script>
export default {
	name: 'register',
  methods:{
    // hidePassword : function name() {
    //   this.passwordHidden = false;         
    // },
    // showPassword : function name() {
    //   this.passwordHidden = true;         
    // },      
    onSubmit(evt) {
      evt.preventDefault()
			console.log(this.$route.query.token)
      // var loader = this.$loading.show({
      //   container: this.$refs.containerButton,canCancel: true,onCancel: this.onCancel,
      // });
      // let checkField = packageGlobals.checkField(
      //   [
      //     {value : this.form.email, message : 'Email wajib diisi',key:'email'}                  
      //   ]
      // )
      // this.alert.status = checkField.flag
      // this.alert.message = checkField.message

      // if (this.form.email != '') {

      // }
      // else
      // {

      // }
      // .catch((error) => {
      //   this.errorMassage = 1
      //   loader.hide()          
      // });
    },
    redirectApp()
    {
      // window.location.href = `https://kebut.page.link/forgot-password/${this.$route.query.token}`;
      window.location.href = `https://kebutkurir.page.link/forgot-password`;      
    }

  },	
  mounted() {
    this.redirectApp()
  }	
}
</script>